// @/common/strings.js
const StringUtils = {
  scrubRfid(value) {
    // remove any non-alphanumeric characters
    return value.replace(/[^0-9a-z]/gi, '').toUpperCase();
  },
  isValidRfid(value) {
    // In this system we're using 4 byte (8 char) UIDs.
    const BYTES_4 = 8;
    const regex = new RegExp(`^[0-9a-fA-F]{${BYTES_4}}$`);
    return regex.test(value);
  },
  reverseRfidBytes(value) {
    // reverse the byte order of the RFID
    let newValue = '';
    for (let i=0; i<value.length; i+=2) {
      newValue = value.slice(i, i+2) + newValue;
    }
    return newValue;
  },
  isValidUsername(value) {
    const MIN_LEN = 4;
    const MAX_LEN = 20;
    const regex = new RegExp(`^[\\w-]{${MIN_LEN},${MAX_LEN}}$`);
    return (value? value.length >= MIN_LEN : false) && regex.test(value);
  },
  isValidEmail(value) {
    const regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
    return regex.test(value);
  },
  isValidUrl(value) {
    const regex = new RegExp('^(https?:\\/\\/)?([\\da-z\\.-]+\\.[a-z\\.]{2,6}|[\\d\\.]+|localhost)([\\/:?=&#]{1}[\\da-z\\.-]+)*[\\/\\?]?$');
    return regex.test(value);
  },
  isValidPassword(value) {
    const regex = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$');
    return regex.test(value);
  },
  isValidCode(value, maxLen=10) {
    const regex = new RegExp(`^[a-zA-Z\\d\\_\\-]{3,${maxLen}}$`);
    return regex.test(value);
  },
  isValidJson(value) {
    try {
      JSON.parse(value);
    } catch(e) {
      return false;
    } 
    return true;
  },
  isTrueOrNull(value) {
    return value === null || value;
  },
  hashCode(s) {
    let h;
    for(let i = 0; i < s.length; i++) 
          h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
  },
  toColor(value) {
    const hash = this.hashCode(value);
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = hash & 0x0000FF;
    const color = "#" + ("0" + r.toString(16)).substr(-2) + ("0" + g.toString(16)).substr(-2) + ("0" + b.toString(16)).substr(-2);
    console.log(color);
    return color;
  },
}

export {
  StringUtils,
}
