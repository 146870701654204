<template>
  <button class="btn" @click="go()"><span class="material-icons icon">arrow_back</span></button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    backRoute: null,
  },
  methods: {
    go() {
      if (this.backRoute) {
        this.$router.replace(this.backRoute);
      } else {
        this.$router.go(-1);
      }
    }
  }
}
</script>

<style scoped>
.icon {
  font-size:xx-large;
}
</style>