<template>
  <div class="d-flex">
    <div class="flex-fill align-self-start w-100 text-left">
      <BackButton v-if="!this.noBackBtn && isBackButtonVisible" :backRoute="this.btnBackRoute" class=""/>
    </div>
    <div class="flex-fill w-auto">
      <img class="app-logo text-center" alt="App Logo" src="@/assets/logo.png"
        @click="goHomeFromLogo">
    </div>
    <div class="flex-fill align-self-start w-100 text-right pt-2 mr-2">
      <button type="button" class="btn btn-danger btn-sm" @click="goHome"
        v-if="isLogoutVisible">Logout</button>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue'
import Vue from 'vue'

export default {
  name: 'PageHeaderKiosk',
  components: {
    BackButton,
  },
  props: {
    noBackBtn: Boolean,
    backRoute: null,
  },
  data() {
    return {
      btnBackRoute: null,
    }
  },
  created() {
    this.btnBackRoute = this.backRoute;
  },
  computed: {
    isLogoutVisible() {
      let visible=false;
      if(this.$store.state.players.player._id){
        return !this.isAnonymousPlayer();
      }
      
      return visible;
    },
    isBackButtonVisible() {
      if(this.$store.state.players.player._id){
        return !this.isAnonymousPlayer();
      } 
      return true;
    }
  },
  methods: {
    goHome() {
      this.$router.replace('/');
    },
    goHomeFromLogo(){
      Vue.prototype.playAudio('button');
      this.$router.replace('/');
    },
    isAnonymousPlayer() {
      if(this.$store.state.players.player.customId == process.env.VUE_APP_ANONYMOUS_USER){
        return true;
      }
      else{
        return false;
      }
    }
  },
}
</script>

<style scoped>
.app-logo {
  cursor: pointer;
}
</style>